<template>
  <div class="collectionDetail">
    <Header>检索门店</Header>
    <div class="container" v-html="$route.query.content"></div>
  </div>
</template>

<script>
export default {
  name: 'collectionDetail',
};
</script>

<style lang="less">
.collectionDetail {
  .container {
    padding: 20px;
    img {
      max-width: 100%;
    }
  }
}
</style>
